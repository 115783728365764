import * as blogShowcase from './blogShowcase.js';
import * as collectionShowcase from './collectionShowcase.js';
import * as eventFeed from './eventFeed.js';
import * as featuredEvents from './featuredEvents.js';
import * as flexibleContent from './flexibleContent.js';
import * as imageCarousel from './imageCarousel.js';
import * as instagramFeed from './instagramFeed.js';
import * as productShowcase from './productShowcase.js';
import * as promoBanner from './promoBanner.js';
import * as pullQuote from './pullQuote.js';
import * as videoShowcase from './videoShowcase.js';

export default [
  blogShowcase,
  collectionShowcase,
  eventFeed,
  featuredEvents,
  flexibleContent,
  imageCarousel,
  instagramFeed,
  productShowcase,
  promoBanner,
  pullQuote,
  videoShowcase
];

export {
  blogShowcase,
  collectionShowcase,
  eventFeed,
  featuredEvents,
  flexibleContent,
  imageCarousel,
  instagramFeed,
  productShowcase,
  promoBanner,
  pullQuote,
  videoShowcase
};
